import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import './contact.css'; // Ensure this is the correct path

const initialState = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

export const Contact = (props) => {
  const [formData, setState] = useState(initialState);
  const { name, email, subject, message } = formData;
  const [feedbackMessage, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          setMessage("Email sent successfully!");
          clearState();
        },
        (error) => {
          console.log("Error in sending email:", error.text);
          setMessage("Error in sending email. Please try again.");
        }
      );
  };

  return (
    <div className="contact-container">
      <div className="contact-card">
        <div className="contact-info">
          <div className="contact-header">
            <h1 className="title">Contact Us</h1>
            <p className="subtitle">
              Have questions, suggestions, or inquiries? Reach out to our team. We're here to assist you on your journey.
            </p>
          </div>

          <div className="contact-details">
            <div className="contact-item">
              <div className="icon">
                <FaMapMarkerAlt />
              </div>
              <div className="text">
                <p className="label">Address</p>
                <p className="value">{props.data ? props.data.address : "Acacia Mall, Kampala"}</p>
              </div>
            </div>

            <div className="contact-item">
              <div className="icon">
                <FaPhoneAlt />
              </div>
              <div className="text">
                <p className="label">Call Us</p>
                <p className="value">{props.data ? props.data.phone : "+256 766427210"}</p>
              </div>
            </div>

            <div className="contact-item">
              <div className="icon">
                <FaEnvelope />
              </div>
              <div className="text">
                <p className="label">Email Us</p>
                <p className="value">{props.data ? props.data.email : "info@terrytechnologie.com"}</p>
              </div>
            </div>
          </div>

          <div className="social-links">
            <div className="social-title">Follow us:</div>
            <div className="social-icons">
              <button className="social-icon"><FaFacebookF /></button>
              <button className="social-icon"><FaTwitter /></button>
              <button className="social-icon"><FaInstagram /></button>
              <button className="social-icon"><FaLinkedinIn /></button>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <form name="sentMessage" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">
                Name <span className="required">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                value={name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">
                Email <span className="required">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                value={email}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="subject">
                Subject <span className="required">*</span>
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                required
                value={subject}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">
                Message <span className="required">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                required
                value={message}
                onChange={handleChange}
                rows="4"
              ></textarea>
            </div>

            <button type="submit" className="submit-button">
              Send Message
            </button>
            {feedbackMessage && <p>{feedbackMessage}</p>}
          </form>
        </div>
      </div>
      {/* Google Map Section */}
      <div className="map-container">
  <h2>Our Location</h2>
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.27304655654!2d32.582519172470054!3d0.3156036738408775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbb4d7a6f37f7%3A0x3c19c39663c19a11!2sAcacia%20Mall%2C%20Kampala%2C%20Uganda!5e0!3m2!1sen!2sus!4v1686205195962!5m2!1sen!2sus&markers=color:red%7Clabel:A%7C0.3156,32.5825"
    width="100%"
    height="450"
    allowFullScreen=""
    loading="lazy"
    title="Google Map"
  ></iframe>
</div>


    </div>
  );
};

export default Contact;